import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Layout from "../../components/Layout"

import styled from "styled-components"
import { LinkCta } from "../../components/Button"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Block from "../../components/Block"

const SubHeading = styled.h3`
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 15px;
  font-weight: 500;

  @media (min-width: 576px) {
    font-size: 1.75rem;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "stock/woman-books.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data.image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const Ratgeber = () => {
  return (
    <Layout title="Ratgeber" mt0 light>
      <Hero heightVh={30}>
        <Background>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>Unsere CBD Ratgeber</HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Block>
          <Row justify="space-between">
            <Col md={6} xl={5}>
              <SubHeading>
                CBD Gleitgel: Das Upgrade für Ihr Liebesleben
              </SubHeading>
              <p>
                In diesem Artikel erfahren Sie alles über den neuesten Trend der{" "}
                <strong>CBD Gleitgele</strong>. Wir erklären, welche Wirkung die
                neuartigen Gleitmittel versprechen und für wen sie sich lohnen.
              </p>
              <div>
                <LinkCta to="/ratgeber/cbd-gleitgel/">
                  Zum CBD Gleitgel Artikel
                </LinkCta>
              </div>
            </Col>
            <Col md={6} xl={5}>
              <SubHeading>Sarah’s Blessing Fruchtgummis</SubHeading>
              <p>
                <strong>Sarah's Blessing Fruchtgummis</strong> sind in aller
                Munde – unser kritischer Test bringt Klarheit.
              </p>
              <div>
                <LinkCta to="/ratgeber/sarahs-blessing-fruchtgummis/">
                  Zum Test
                </LinkCta>
              </div>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row justify="space-between">
            <Col md={6} xl={5}>
              <SubHeading>
                CBD kaufen ist legal – das sagen EuGH und BGH dazu
              </SubHeading>
              <p>
                Hier erfahren Sie, begründet durch{" "}
                <strong>aktuelle Gerichtsurteile</strong>, wieso{" "}
                <strong>CBD in Deutschland legal</strong> ist und Sie somit
                unbesorgt entsprechende CBD Produkte kaufen und konsumieren
                können.
              </p>
              <div>
                <LinkCta to="/ratgeber/cbd-legalitaet/">
                  Zum Artikel über die Legalität von CBD
                </LinkCta>
              </div>
            </Col>
            <Col md={6} xl={5}>
              <SubHeading>THC Grenzwert Erhöhung</SubHeading>
              <p>
                Im Februar 2023 passt Deutschland die Vorgaben zum THC Grenzwert
                an und <strong>erhöht diesen von 0,2% auf 0,3%</strong>. In
                unserem Artikel erfahren Sie alle Details zu der Änderung und
                was diese für Sie bedeutet.
              </p>
              <div>
                <LinkCta to="/ratgeber/thc-gehalt-erhoehung/">
                  Mehr Informationen zur Erhöhung
                </LinkCta>
              </div>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row justify="space-between">
            <Col md={6} xl={5}>
              <SubHeading>
                Vor- und Nachteile der zwei häufigsten CBD Produkte
              </SubHeading>
              <p>
                In unserem Ratgeber geben wir Ihnen einen Überblick{" "}
                <strong>zu den zwei häufigsten CBD Produkten</strong> und
                stellen die wichtigsten <strong>Vorteile und Nachteile</strong>{" "}
                gegenüber.
              </p>
              <div>
                <LinkCta to="/ratgeber/produkt-ratgeber/">
                  Zum Produkt Ratgeber
                </LinkCta>
              </div>
            </Col>
            <Col md={6} xl={5}>
              <SubHeading>CBD Öl Test</SubHeading>
              <p>
                In unserem CBD Öl Selbsttest erhalten Sie nach dem Beantworten
                nur weniger Fragen eine{" "}
                <strong>persönliche Empfehlung für CBD Öl Produkte</strong>, die
                am besten zu Ihnen passen.
              </p>
              <div>
                <LinkCta to="/ratgeber/cbd-oel-test/">Zum Selbsttest</LinkCta>
              </div>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default Ratgeber
